import React from 'react';
import Layout from '../common/layouts';
import {graphql} from 'gatsby';
import Seo from '../common/seo';
import Hero from '../homepage/components/hero';
import Content from '../homepage/components/content';
import Copy from '../homepage/components/copy';


export default ({props, data}) => (
    <Layout>
        <Seo
            title={`Commercial ${data.site.siteMetadata.title}`}
            description={data.markdownRemark.frontmatter.title}/>
            <Hero title="Commercial Clearances" image={data.banner.childImageSharp.fluid} description="Office Furniture">
                <p>We can clear offices, warehouses, care homes,  storage units, waste in commercial premises and contents of shops.
                    We can clear all commercial premises to help you free up space so that the property is ready for
                    sale or for a property to be let/ rented again.</p>
                <p>We provide a structured service and our waste is either resold, recycled, donated and very minimal
                    amount is for landfill.</p>
            </Hero>
            <Content image={data.post.childImageSharp.fluid} description="books">
                <Copy title="Licenses and insurance">
                    <p>
                        We currently have public liability insurance that covers up to £2,000,000 and we have a waste
                        carriers licence.
                        So if you have a property that needs to be cleared please call or email us and we will be happy to
                        help.
                    </p>
                </Copy>
                <Copy title="Areas covered">
                    <p>
                        The areas we cover to clear contents are:
                        Clacton-on-Sea, Frinton-on-Sea, Holland-on-Sea, Walton-on-the-Naze, Kirby Le Soken, Colchester,
                        Tendring, Essex, Suffolk, Hertfordshire and the borough of Enfield (North London areas).
                    </p>
                </Copy>
            </Content>
    </Layout>
)

export const dataQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: {name: {eq: "seo__info"}}) {
      html
      frontmatter {
        title
      }
    }
    banner: file(relativePath: {eq: "posts/img/commercial_clearance_hero.png"}) {
      childImageSharp {
        fluid(maxHeight: 720, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    post: file(relativePath: {eq: "posts/img/commercial_clearance_post.png"}) {
      childImageSharp {
        fluid(maxHeight: 705, maxWidth: 1042) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
