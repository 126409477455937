import React from "react"
import '../../common/styles/custom.tachyons.css'
import 'tachyons';

export default (props) => (
    <React.Fragment>
        <div className="w-100 mw6">
            <span className="subheading">{props.title}</span>
            <div className="lh-copy f5 display mt4">
                {props.children}
            </div>
        </div>
    </React.Fragment>
)
