import React from 'react';
import Layout from '../common/layouts';
import { graphql } from 'gatsby';
import Seo from '../common/seo';
import Hero from '../homepage/components/hero';
import Content from '../homepage/components/content';
import Copy from '../homepage/components/copy';

export default ({props, data}) => (
  <Layout>
      <Seo
          title={`Residential ${data.site.siteMetadata.title}`}
          description={data.markdownRemark.frontmatter.title} />
      <Hero
          title="House Clearances"
          image={data.banner.childImageSharp.fluid}
          description="Residential clearances">
          <p>
              We provide a discreet and professional clearance service mainly in Essex and Suffolk.
              We will clear your property, pack your items, take away the items and hoover up/ clean up once finished.
              We recycle most of the items we clear.
          </p>
      </Hero>
      <Content image={data.post.childImageSharp.fluid} description="residential items for clearing">
          <Copy title="Unwanted items or furniture?">
              <p>Are you planning to move into a new home? Have you moved into a new home? Would you like to get rid of any unwanted items or furniture?</p>
              <p>Here at A&P Clearances we can handle any type of clearance jobs including a single item to the removal of a full hoarder house. Your property is in great hands. Please enquire for a friendly quote.</p>
          </Copy>
      </Content>
  </Layout>
)

export const dataQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: {name: {eq: "seo__info"}}) {
      html
      frontmatter {
        title
      }
    }
    banner: file(relativePath: {eq: "posts/img/house_clearance_hero.png"}) {
      childImageSharp {
        fluid(maxHeight: 720, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    post: file(relativePath: {eq: "posts/img/house_clearance_post.png"}) {
      childImageSharp {
        fluid(maxHeight: 702, maxWidth: 1040) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
