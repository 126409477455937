import React from 'react';
import Layout from '../common/layouts';
import { graphql } from 'gatsby';
import Seo from '../common/seo';
import Hero from "../homepage/components/hero";
import Content from "../homepage/components/content"
import Copy from "../homepage/components/copy"


export default ({props, data}) => (
  <Layout>
    <Seo
      title={`About ${data.site.siteMetadata.title}`}
      description={data.markdownRemark.frontmatter.title} />
      <Hero
          title="About"
          image={data.banner.childImageSharp.fluid}
          description="About us">
          <p>
              We have over 10 years experience clearing properties in Essex and North London.
              Myself and my wife clear properties together and have plenty of experience in this profession.
              We enjoy a challenge and thrive to obtain excellent satisfaction.
          </p>
      </Hero>
      <Content image = {data.post.childImageSharp.fluid} description= "The Author">
          <Copy title="Our Services">
              <p>
                  We provide a discreet and reliable clearance services. If you are
                  looking for a property clearance company, please look no further
                  than A&P Clearances. We have over 10 years of experience so you can
                  count on us to provide you with domestic and commercial clearance
                  services.
              </p>
              <p>
                  No job is too big or too small for us; we love our job and enjoy
                  working as a clearance company. Trust us to complete all work to a
                  high standard at all times. We offer great customer service and quick
                  turnarounds on all work carried out. We mainly work throughout
                  Essex and Suffolk.
              </p>
              <p>
                  On occasion we would buy items out of a property.
              </p>
              <p>
                  Payment: Cash, UK personal cheque, Paypal.
              </p>
          </Copy>
          <Copy title="Estate agents and solicitors">
              <p>
                  We have previously acted on behalf of estate agents and solicitors to
                  clear different properties. We are more than happy to provide our
                  services to each of these professions. Please contact us for any
                  further information or to obtain a quote to clear a property.
              </p>
          </Copy>
      </Content>
  </Layout>
)

export const dataQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: {name: {eq: "seo__info"}}) {
      html
      frontmatter {
        title
      }
    }
    banner: file(relativePath: {eq: "posts/img/about_post.png"}) {
      childImageSharp {
        fluid(maxHeight: 720, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    post: file(relativePath: {eq: "posts/img/van.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 702, maxWidth: 1040) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
