import React from "react"
import Img from 'gatsby-image';
import '../../common/styles/custom.tachyons.css'
import 'tachyons';

export default (props) => (
  <React.Fragment>
    <Img
      className="w-100 vh-40 center"
      fluid={props.image}
      alt={props.description} />
    <div
      className="h-auto bg-near-white mw9 w-100 flex flex-column items-center justify-center pv4 ph2 center">
      <span className="header1">{props.title}</span>
      <div className="display mw7 tc f5 dn dib-l db lh-copy">
        {props.children}
      </div>
    </div>
  </React.Fragment>
)
