import React from "react"
import Img from 'gatsby-image';
import '../../common/styles/custom.tachyons.css'
import 'tachyons';

export default (props) => (
    <React.Fragment>
        <div className="pa2 flex flex-wrap mw9 center justify-around items-start">
            <div className="pb4 w-100 mw6">
                <Img fluid={props.image} alt={props.description} />
            </div>
            <div className="w-100 mw6">
                {props.children}
            </div>
        </div>
    </React.Fragment>
)
