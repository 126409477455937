import React from 'react';
import {graphql, StaticQuery} from 'gatsby';

export default () => (
    <StaticQuery query={graphql`
  query {
    site {
      siteMetadata {
        emailAddress
        phoneNumber1
        phoneNumber2
        phoneNumber3
      }
    }
  }
`}
                 render={(data) => (
                     <div>
                         <div>
                             <div className="contact">{data.site.siteMetadata.phoneNumber1}</div>
                             <div className="contact">{data.site.siteMetadata.phoneNumber2}</div>
                             <div className="contact">{data.site.siteMetadata.phoneNumber3}</div>
                             <div className="contact">{data.site.siteMetadata.emailAddress}</div>
                         </div>
                     </div>
                 )}
    />
)
