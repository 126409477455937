import React from 'react';
import Layout from '../common/layouts';
import { graphql } from 'gatsby';
import Seo from '../common/seo';
import Social from '../homepage/components/social';
import Hero from '../homepage/components/hero';
import ContactDetails from '../homepage/components/contactDetails';

export default ({props, data}) => (
  <Layout>
    <Seo
      title={`Contact ${data.site.siteMetadata.title}`}
      description={data.markdownRemark.frontmatter.title} />
      <Hero title="Contact" image={data.banner.childImageSharp.fluid} description="phone and diary">
          <p>
              Please enquire for a friendly quote:
          </p>
          <ContactDetails />
      </Hero>
    <Social />
  </Layout>
)

export const dataQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        emailAddress
        phoneNumber1
        phoneNumber2
        phoneNumber3
      }
    }
    markdownRemark(frontmatter: {name: {eq: "seo__info"}}) {
      html
      frontmatter {
        title
      }
    }
    banner: file(relativePath: {eq: "posts/img/contact_hero.png"}) {
      childImageSharp {
        fluid(maxHeight: 720, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
