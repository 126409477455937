import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Content from './content';
import Copy from './copy';
import 'tachyons'


export default () => (
    <StaticQuery
      query={graphql`
        query {
          image: file(relativePath: {eq: "img/us.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1080) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }  
      `}
      render={(data) => (
        <React.Fragment>
            <Content image = {data.image.childImageSharp.fluid} description= "The Authors">
                <Copy title="Hi! We are Alex and Penny">
                    <p>We are a husband and wife team with 10 years of experience in the house clearance industry.</p>

                    <p>Most of our work comes from word of mouth recommendations from across Essex and Suffolk.</p>
                </Copy>
            </Content>
        </React.Fragment>
    )} />
)
