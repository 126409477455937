import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';


export default () => (
    <StaticQuery
        query={graphql`
      query {
      facebook: file(relativePath: {eq: "img/facebook.png" }) {
            childImageSharp {
              fluid(maxWidth: 166) {
                ...GatsbyImageSharpFluid
              }
            }
          }
      instagram: file(relativePath: {eq: "img/insta.png" }) {
            childImageSharp {
              fluid(maxWidth: 166) {
                ...GatsbyImageSharpFluid
              }
            }
          }          
      }  
    `}
        render={(data) => (
            <div className="bg-lightest-blue flex flex-column justify-center items-center center pa2 pv5">
                <h1 className="subheading tc">See what we've been getting up to</h1>
                <div className="social">
                    <div className="socialItem">
                        <a href="https://www.facebook.com/AP-Clearances-111888950505045" className="w-auto db">
                            <div className="mw3 center">
                                <Img fluid={data.facebook.childImageSharp.fluid} alt="Facebook Link"/>
                            </div>
                            <p className="f4 display mw7 tc lh-copy">Visit our Facebook page</p>
                        </a>
                    </div>
                    <div className="socialItem">
                        <a href="https://www.instagram.com/aandpclearances/" className="w-auto db">
                            <div className="mw3 center">
                                <Img fluid={data.instagram.childImageSharp.fluid} alt="Instagram Link"/>
                            </div>
                            <p className="f4 display mw7 tc lh-copy">Visit our Instagram page</p>
                        </a>
                    </div>
                </div>
            </div>
        )}/>
)
